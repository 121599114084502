* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.custom-title {
  font-weight: bold;
  color: black;
  font-size: 15px;
}

.custom-tablename-column {
  font-size: 16px;
  text-align: center;
}

.action-btn .action {
  font-size: 18px;
  padding: 10px;
  border: none;
  border-radius: 50%;
  box-shadow: rgba(120, 119, 119, 0.35) 5px 5px 15px;
}

.action-btn .action:nth-child(1) {
  margin-right: 10px;
}

.order-btn {
  position: absolute;
  bottom: 80px;
  right: 50px;
  background-color: green;
  color: white;
  font-size: 15px;
}

.blankCart {
  display: flex;
  justify-content: center;
  align-content: center;
}

.blankCart img {
  filter: hue-rotate(216deg);

}