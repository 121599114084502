*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.DeleteButton{
    margin-right: 10px;
}

.DeleteButton:hover{
    cursor: pointer;
    background-color: red !important;
    color: white !important;
    border: none;
}
.UpdateButton:hover{
    cursor: pointer;
    background-color: #1677ff !important;
    color: white !important;
    border: none;
}