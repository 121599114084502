*{
    margin: 0 ;
    padding: 0;
    box-sizing: border-box;
}
.card-wrapper{
    display: flex;
    flex-direction: row;
    margin: 20px;
}
.txt-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-left: 20px;
    width: 900px;
}


img.ant-image-img.viewOrderImg{
    width: 250px;
    height: 250px;
    /* height: 50%; */
}
.quantiy-wrapper,.time-wrapper{
    text-align:center ;
}
.quantiy-wrapper p:nth-child(1), .time-wrapper p:nth-child(1){
    font-weight: 700;
    color: #5e82bf;
    /* color: #15305c;; */
}

.ViewCartImage{
   
    display: flex;
    justify-content: center;
    align-content: center;
} 

 .viewCart{
    filter: hue-rotate(159deg);
    width: 700px;
    margin-top: 10px;
}


@media screen and (max-width : 800px) {

    .viewOrderMainDiv{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }
    .viewOrderCard{
        width: 500px;
        margin-bottom: 20px;
    }
    .card-wrapper{
        /* flex-direction: column; */
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        width: 400px;
      
 
    }
    .txt-wrapper{
        display:flex ;
        flex-direction: column;
        margin-top: 15px;
   
    }

    .quantiy-wrapper , .time-wrapper{
        display:flex ;
        flex-direction: row;
    
    }

    .quantiy-wrapper p:nth-child(1), .time-wrapper p:nth-child(1){
    font-weight: 700;
 font-size: 18px;
 margin-right:10px ;
  
}

.quantiy-wrapper p:nth-child(2), .time-wrapper p:nth-child(2){
    font-size: 18px;   
}
   
    .txt-wrapper h3{
        font-size: 25px;

    }

.viewCartImgDiv{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

    img.ant-image-img.viewOrderImg{
        width: 300px;
        height: 300px;
       
    }

}

@media screen and (max-width : 767px){

    .viewOrderMainDiv{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        

    }
    
    .viewOrderCard{
        /* background-color: rgb(0, 238, 255); */
        width: 100%;
        max-width: 60%;
        margin:  0 auto;
        margin-bottom: 20px;
    }
    
    .card-wrapper{ 

        width: auto;
    padding: 20px;


    }
   
    .viewCartImgDiv{
        display: flex;
        justify-content: center;
        align-items: center;
  
    }
    
        img.ant-image-img.viewOrderImg{
            width: 200px;
            height: 200px;
            object-fit: contain;
           
        }

        .txt-wrapper{
          
            display:flex ;
      text-align: center;
            margin-top: 15px;
       
        }
}
