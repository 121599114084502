.settingWrapper {
    .tableWrapper {
        .ant-table-thead {
            th {
                font-weight: bold;
                font-size: 15px;
            }
        }

        tr {
            td {
                text-align: center;
            }
        }
    }
}

.slackMsg {
    text-align: end;
}