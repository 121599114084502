*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.inFraSelect  .ant-select-selector{
    width: 100%;
    
}
.settingWrapper {
    .tableWrapper {
        .ant-table-thead {
            th {
                font-weight: bold;
                font-size: 15px;
            }
        }

        tr {
            td {
                text-align: center;
            }
        }
    }
}