.userTable .ant-table-thead>tr>th {
    color: #fff !important;
    background: #000 !important;

}

.userTable .ant-table-thead>tr>th span.custom-address-title {
    color: white;
}

.userTable .ant-table-content {
    margin-top: 10px;
}