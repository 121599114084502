* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
}

.card {
    width: 24vw;
    height: 100%;

}

.card-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.tokenbtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.tokenbtn p {
    margin: 0px 40px 0px 40px;
    font-size: 20px;
}

.tokenbtn .in-btn {
    font-size: 14px;
    padding: 10px;
    border: none;
    border-radius: 15%;
    box-shadow: rgba(120, 119, 119, 0.35) 5px 5px 15px;
}

.cart {
    font-size: 30px;
}

.cart-wrapper {
    text-align: right;
    padding-right: 10px;
    margin-bottom: 10px;
    position: relative;
}

.cart-wrapper .items-num {
    position: absolute;
    right: 10px;
    background-color: red;
    padding: 0.1rem 0.45rem;
    color: white;
    border-radius: 70%;
    transform: translate(50%, -50%);
    transform-origin: 100% 0%;
    margin-bottom: 0 !important;
    top: 0px;
}

.option-list {
    display: flex;
    flex-direction: row;
}

.option-list .list {
    margin-left: 20px;
}

img.ant-image-img.card-image.css-dev-only-do-not-override-1k979oh {
    width: 250px;
    height: 250px;
}

.card-data {
    width: 16vw;
}

.foodName h4 {
    font-size: 18px;
    color: #15305c;

}

.foodlist {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px auto
}

.foodlist span {
    color: #0f2c5b;
    font-weight: 700;
}

.disabled-food {
    opacity: 0.5;
    /* You can adjust the opacity to visually indicate the disabled state */
    pointer-events: none;
    /* Disable pointer events to prevent interactions */
}

.disabled-message {
    font-style: italic;
    color: red;
    /* You can adjust the color to match your design */
}

.loaderOuter {
    position: relative;
    height: 100%;
}

.loaderSpin {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
}

@media (max-width: 1200px) {
    .card-data {
        width: 20vw;
    }

    .foodName h4 {
        font-size: 17px;
        text-align: center;
    }

    .foodlist {
        margin: 5px auto;
    }

    img.ant-image-img.card-image.css-dev-only-do-not-override-1k979oh {
        width: 200px;
        height: 200px;
    }

    .tokenbtn p {
        margin: 0px 40px 0px 40px;
        font-size: 17px;
    }

    .tokenbtn .in-btn {
        font-size: 17px;
        padding: 9px;
        border: none;
        border-radius: 50%;
        box-shadow: rgba(120, 119, 119, 0.35) 5px 5px 15px;
    }
}

/* Media query for devices with a maximum width of 768 pixels */
@media (max-width: 990px) {

    .card {
        width: 40vw;

    }

    .card-data {
        width: 30vw;
    }

    .foodName h4 {
        font-size: 15px;
    }

    img.ant-image-img.card-image.css-dev-only-do-not-override-1k979oh {
        width: 250px;
        height: 250px;
    }

    .foodlist p span {
        font-size: 15px;
    }
}



@media(max-width:764px) {
    .column {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;
    }

    .card {
        width: 70vw;
        height: 100%;
    }

    .foodName h4 {
        font-size: 18px;
    }

    .foodlist p span {
        font-size: 16px;
    }

    .cart-wrapper .items-num {
        position: absolute;
        top: 5.3rem;
        right: 30px;

        padding: 0.1rem 0.45rem;

        border-radius: 70%;
        transform: translate(50%, -50%);
        transform-origin: 100% 0%;
    }
}


@media (max-width: 430px) {
    img.ant-image-img.card-image.css-dev-only-do-not-override-1k979oh {
        width: 200px;
        height: 200px;
    }
}

.descList {
    margin-bottom: 0;
    padding: 0.5rem 0;
    border-bottom: 1px solid #f0f0f0;
}

.descList:last-child {
    border: none;
    padding-bottom: 0;
}