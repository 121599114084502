 .foodTable .ant-table-thead>tr>th {
     color: #fff !important;
     background: #000 !important;
 }

 .foodTable .ant-table-thead>tr>th span.custom-address-title {
     color: white;
 }

 .foodTable .ant-table-thead>tr>th .custom-title {
     color: white;
 }

 td.ant-table-cell.quantityTable {
     text-align: center;
 }


 .foodTable .ant-table-content {
     margin-top: 10px;
 }