.header {
    display: flex;
    flex-direction: row;
}

.header-wrapper {
    margin-left: 20px;
}

.header-txt {
    display: flex;
    flex-direction: row;
}

.menu-wrapper {
    display: flex;
    flex-direction: column;
}

.icon-change {
    margin-right: 10px;
    width: 20px;
    margin-top: 10px;
    padding-top: 10px;
    background: none;
}


.ant-drawer-close {
    right: 0 !important;
    left: unset !important;
}

.ant-drawer-header.ant-drawer-header-close-only {
    position: absolute;
    line-height: 0 !important;
    right: 5px;
    border-bottom: 0;
}

.brand {
    padding: 0;
}


.headerImage img.ant-image-img.css-dev-only-do-not-override-42nv3w {
    width: 50px !important;
    height: 50px !important;
    border-radius: 50px !important;
}

@media screen and (max-width: 992px) {
    .usermenu {
        width: 100%;
        padding-left: 24px;
    }

    .menu-item {
        padding-inline: 0% !important;
    }

    .menu-btn {
        width: 50px;
        padding-inline: 0% !important;
        border: 1.5px solid rgba(175, 80, 80, 0.07);
        padding-left: 34px;
    }

    .menu-wrapper {
        width: auto;
    }
}