* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.MainFormTime {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.Add-time-btn {
    display: flex;
    margin-left: 14px;
}

.update-btn {
    width: 180px;

}

.loaderOuter{
    position: relative;
}
 
.loaderSpin{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    left: 50%;
    top: 50%;
    transform:  translate(-50%, -50%);
    z-index: 1;
}
.time-title {
    font-weight: 700;
    color: black;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.edit-wrapper {
    border: none;
    box-shadow: none;
}

.edit-icon {
    color: #1890ff;
    font-size: 20px;
}

.timing, .Option-Name, .editbtn-wrapper {
    text-align: center;
}

.formTiming {
    width: 290px;
}

.time-table {
    margin: 15px;
    border: 0.5px solid #f0f0f0;
    box-shadow: rgba(120, 119, 119, 0.35) 2px 2px 6px;
}