
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
}
.updateticket {
    padding: 4rem;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;   
}
 .updateticket h1{
    font-size: 16px;
    margin-top: 20px;
}
.updateticket h5 {
font-size: 14px;
}
.update-img{
  display: flex;
    justify-content: center;
    align-items: center;
}
.imagewrapper1  {
    padding: 2rem;
  border-radius: 115px;   
}
.imagewrapper1 .list-img{
       border-radius: 30px;
       height: 300px;
}
.signinRow{
padding: 1rem 6rem !important;
}