*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
}
.FormTicket {
    padding: 2rem;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    margin-bottom: 20px;   
}

.foodName{
    width: 60%;
}
 .FormTicket h1{
    font-size: 16px;
    margin-top: 20px;
}
.FormTicket h5 {
font-size: 14px;
}
.imagewrapper{
    padding: 2rem;
    width: 500px;
}
 .imagewrapper img{
    filter: hue-rotate(200deg)
}
