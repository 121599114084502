* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

}

span.icon-change {
    background: none;
}

@media screen and (max-width: 1500px) {
    .drawer-conatiner {
        display: none;
    }
}

@media screen and (max-width: 1200px) {
    .drawer-conatiner {
        display: flex;
    }

    .layout-dashboard .ant-layout {
        margin-left: 0 !important;
    }
}