* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.white-row {
  background-color: rgb(251, 246, 246);
}

.lightblue-row {
  background-color: rgb(235, 243, 255);
}

.custom-name-column {
  font-size: 15px;
}

.custom-address-column {
  font-style: italic;
}

.custom-address-title {
  font-weight: bold;
  color: black;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Assign-btn {
  width: 120px;
  height: 40px;
}

span {
  text-align: center;
}

.btn-form {
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.formbtn {
  width: 100px;
  font-size: 15px;
}

.model-card {
  margin-top: 24px;
  box-shadow: rgba(120, 119, 119, 0.35) 0px 5px 15px;
}

.ant-modal-content {
  border-radius: 5px;
}

.user-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 1000px;
width: 1000px; */
}

.ant-modal-body:nth-child(2) {
  width: 60vw;
  /* min-width: 20vw; */
  max-width: 60vw;
  height: auto;
}

.card-style {
  box-shadow: rgba(120, 119, 119, 0.35) 0px 5px 15px;
  margin: 18px 0px 0px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 18vw; */
  width: auto !important;
}

.userform {
  width: 470px
}

.add-btn {
  margin-top: 25px;
}

.btn-wrapper {
  margin: 10px;
  display: flex;
  flex-direction: row;
}

.btn-wrapper .btn {
  font-size: 15px;
  color: white;
}

.btn-wrapper .btn:nth-child(1) {
  margin-right: 20px;
  background-color: red;
  border: none;
}

.btn-wrapper .btn:nth-child(2) {
  background-color: #1890ff;
}

.listofName p {
  font-size: 15px;
}

.Update-Form {
  width: 450px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.dropdown-item {
  margin-top: 20px;
}

.Update-Form .update-btn {
  width: 320px;
  background-color: #1890ff;
  color: white;
  font-size: 15px;
}

.del-icon {
  text-align: center;
  color: rgb(247, 28, 28);
  font-size: 20px;
}

.delete-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: transparent;
  box-shadow: none;
}

.btn-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loaderOuter{
    position: relative;
}
 
.loaderSpin{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  
    left: 50%;
    top: 50%;
    transform:  translate(-50%, -50%);
    z-index: 1;
}

@media screen and (max-width:630px) {
  .table {
    background-color: aliceblue;
    overflow-x: auto;
  }
}

button.ant-btn.css-dev-only-do-not-override-42nv3w.ant-btn-primary.ant-input-search-button {
  height: 50px !important;
}