/* General Styles */
.signinRow {
    padding: 20px;
    /* background-color: #fff; */
    border-radius: 8px;

  }
  
  h5.ant-typography.title.css-dev-only-do-not-override-42nv3w {
    font-size: 25px;
    text-align: center;
}

  .row-col1 {
    width: 100%;
    max-width: 400px;
    margin: auto;
  }
  
  .register-input .ant-input,
  .register-input .ant-input-password {
    width: 100%;
    height: 55px;
    border-radius: 8px;
    border: 1px solid #ccc;
    padding: 0 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s;
  }
  
  .register-input .ant-input:hover,
  .register-input .ant-input-password:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .register-input .ant-input:focus,
  .register-input .ant-input-password:focus {
    outline: none;
    border-color: #1890ff;
    box-shadow: 0 4px 8px rgba(24, 144, 255, 0.5);
  }
  
  .register-button {
    width: 100%;
    height: 45px;
    border-radius: 8px;
    background-color: #1890ff;
    color: #fff;
    font-weight: bold;
    transition: background-color 0.3s;
  }
  
  .register-button:hover {
    background-color: #40a9ff;
  }
  
  
  