*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.userRemainingToken{
padding-left: 20px;
}

.userDatePicker{
    padding-left: 20px;
}
