* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.Assign-card {
  width: 24vw;
  /* height: 100%; */

}

.Assign-card-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/* .Assign-image-class{
    width: 200px;
    height: 200px;
} */

img.ant-image-img.Assign-card-image.css-dev-only-do-not-override-1k979oh {
  width: 200px;
  height: 200px;
}



.loaderOuter {
  position: relative;
}

.loaderSpin {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.Assign-card-data {
  width: 16vw;
}

.Assign-foodName h4 {
  font-size: 18px;
  color: #15305c;

}

.Assign-foodlist {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px auto
}

.Assign-foodlist span {
  color: #0f2c5b;
  font-weight: 700;
}


@media (max-width: 1200px) {
  .Assign-card-data {
    width: 20vw;
  }

  .Assign-foodName h4 {
    font-size: 17px;
    text-align: center;
  }

  .Assign-foodlist {
    margin: 5px auto;
  }

  img.ant-image-img.card-image.css-dev-only-do-not-override-1k979oh {
    width: 200px;
    height: 200px;
  }

}

/* Media query for devices with a maximum width of 768 pixels */
@media (max-width: 990px) {

  .Assign-card {
    width: 40vw;

  }

  .Assign-card-data {
    width: 30vw;
  }

  .Assign-foodName h4 {
    font-size: 15px;
  }

  img.ant-image-img.card-image.css-dev-only-do-not-override-1k979oh {
    width: 250px;
    height: 250px;
  }

  .Assign-foodlist p span {
    font-size: 15px;
  }
}



@media(max-width:764px) {
  .column {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
  }

  .Assign-card {
    width: 70vw;
    height: 100%;
  }

  .Assign-foodName h4 {
    font-size: 18px;
  }

  .Assign-foodlist p span {
    font-size: 16px;
  }
}


@media (max-width: 430px) {
  img.ant-image-img.card-image.css-dev-only-do-not-override-1k979oh {
    width: 200px;
    height: 200px;
  }
}

.foodCard .ant-card-cover {
  height: 300px;
  border-bottom: 1px solid #FEFAFA;
}

.foodCard .ant-card-cover img {
  height: 100%;
  object-fit: cover;
  object-position: center center;
}

.foodCard .ant-card-meta .ant-card-meta-title {
  color: #5f82bf;
  font-size: 1.25rem;
  font-weight: 700;
}

.foodCard .ant-card-actions span {
  color: #212529;
}