*{
    margin:0;
    padding: 0;
    box-sizing: border-box;
}
@media screen and (max-width : 940px){
    .just{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .copyright{
     
        width: 58vw;
   
       
    }
  
}

@media screen and (max-width : 768px){
    .copyright{
        width: 40vw;

    }
}