* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.row-white {
    background-color: rgb(251, 246, 246);
}

.row-blue {
    background-color: rgb(244, 248, 255)
}

.custom-title {
    font-weight: 700;
    color: black;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.custom-data {
    font-size: 15px;
}

.custom-date {
    font-size: 15px;
    text-align: center;
}

.quantity {
    text-align: center;
}

.custom-name {
    text-align: center;
    margin: 7px;
}

.order-btns{
      background-color: green;
  color: white;
  font-size: 15px;
}

.loaderOuter{
    position: relative;
}
 
.loaderSpin{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    left: 50%;
    top: 50%;
    transform:  translate(-50%, -50%);
    z-index: 1;
}

.datePicker{
    padding: 10px;
    margin-right: 10px;
}



.deleteOrder{
    color: red;
    font-size: 25px;
    display:flex ;
    justify-content: center;
}
.ant-dropdown .ant-table-filter-dropdown .ant-dropdown-menu .ant-dropdown-menu-item .ant-dropdown-menu-title-content {
    flex: none !important;
}

@media screen and (max-width:630px) {
    .table-wrapper {
        overflow-x: auto;
    }
}