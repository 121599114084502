* {
    margin: 0;
    box-sizing: border-box;
    padding: 0;
    font-family: "Poppins", sans-serif;
}

.cardbdy {
    margin: 20px;
    height: 400px;
}

.imgdiv {
    width: 200px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.imgdiv {
    width: 100%;
    height: 100%;
}

.content {
    margin: 15px;
}

.heading h1 {
    font-size: 20px;
    font-weight: 700;
    color: #003bab;
}

.ListticketContent p {
    font-size: 17px;
    font-weight: 700;
}

.btnwrapper .btn {
    width: auto;
    height: 7vh;
    margin: 15px;
    font-size: 18px;
    font-weight: 500;
}

.loaderOuter{
    position: relative;
    height: 100%;
}
 
.loaderSpin{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    left: 50%;
    top: 50%;
    transform:  translate(-50%, -50%);
    z-index: 1;
}
.space {
    display: flex;
    justify-content: right;
    align-content: center;
    margin-right: 20px;
}

.pagination {
    margin-bottom: 20px;
    padding: 20px;
}

button.ant-btn.css-dev-only-do-not-override-1k979oh.ant-btn-primary.ant-input-search-button {
    height: 50px;
}

@media screen and (max-width:1600px) {
    .btnwrapper {
        display: flex;
        width: 100%;
        justify-content: center;
    }

    .btnwrapper button {
        font-size: 14px;
    }
}

.layout-dashboard .ant-layout-sider.sider-primary {
    display: block !important;
}

@media screen and (max-width:1200px) {
    .btnwrapper button {
        font-size: 12px;
    }

    .layout-dashboard .ant-layout {
        margin-left: 0 !important;
    }

    .layout-dashboard .ant-layout-sider.sider-primary {
        display: none !important;
    }
}

.blanklist{
    filter: hue-rotate(210deg);
}